<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 尾部组件
-->
<template>
  <div class="footer_out">
      <div class="footer w1200">
        <div class="left">
          <img class="footer-logo" src="@/assets/img/index/logo.png" alt="">
          <span>{{ '总部地址：尖沙咀梳士巴利道18号 K11 ATELIER 写字楼18楼1804 室' | sLang }}</span>
        </div>
        
        <div class="right">
          <span>{{ '官方联系方式' | sLang }}</span>
          <div class="phone">{{ '852-39931350' | sLang }}</div>
          <span>{{ '邮箱：customerservice@cihkinsure.com' | sLang }}</span>
        </div>  
      </div>
    <div class="bottom">
      Copyright © 2023  Converge (HK) Insurance Brokers Limited  {{ '版权所有' | sLang}}
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'myFoot',
  methods: {
    changePage(i) {
      this.$emit('changePage', i)
    },
  },
}
</script>

<style lang='scss'>
.footer_out {
  width: 100%;
  background: #FFFFFF;
  text-align: center;
  height: 3.55rem;
  background: url(../../assets/img/index/foot.png) no-repeat left bottom / 100% 100%;

  .footer{
    padding-top: 1.58rem;
    padding-bottom: 0.28rem;
    border-bottom: 0.01rem solid rgba(255,255,255,0.32);
    overflow: hidden;
    .footer-logo{
      display: block;
      width: 2.00rem;
      margin-bottom: 0.15rem;
    }
    span{
      display: block;
      height: 0.16rem;
      font-size: 0.14rem;
      // font-family: HiraginoSansGB-W3, HiraginoSansGB;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 0.16rem;
    }
    .right{
      text-align: left;
      padding-top:0.05rem;
      .phone{
        height: 0.34rem;
        font-size: 0.34rem;
        // font-family: HiraginoSansGB-W6, HiraginoSansGB;
        font-weight: 600;
        color: #FFFFFF;
        margin:0.12rem 0 0.24rem;
      }
    }
  }
  .bottom{
    padding-top: 0.20rem;
    font-size: 0.12rem;
    // font-family: HiraginoSansGB-W6, HiraginoSansGB;
    font-weight: 600;
    color: rgba(255,255,255,0.5);
  }
}
</style>